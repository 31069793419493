<template>
  <div id="notfound">
    <div class="notfound">
      <div class="notfound-404">
        <h1>404</h1>
      </div>
      <h2>Üzgünüz, aradığınız sayfayı bulamadık.</h2>
      <a
        href="javascript:;"
        @click="
          () => {
            $router.push({
              name: 'homedashboard',
            });
          }
        "
      >
        <span class="nav-text">Anasayfaya git</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import "./style.css";
</style>